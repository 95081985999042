<template>
  <div class="desktopLayoutClass">
    <v-app style="min-height: 80vh !important;">
      <div
        v-show="show_header"
        class="header"
        style="
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          padding-left: 5px;
           background-color:white;
        "
      >
        <div style="display: flex; flex-direction: row; width: 100%">
          <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToHomePage()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.UserProfile.FAQ") }}</span>
          </div>
        </div>
      </div>

      <div
        style="height: 90vh; overflow: auto; float: left; position: relative"
      >
        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p><b>{{ $t("Customer.FAQ.Question1") }}</b></p>
          <p>
            {{ $t("Customer.FAQ.Answer1") }}
          </p>
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p><b> {{ $t("Customer.FAQ.Question2") }}</b></p>
          <p>{{ $t("Customer.FAQ.Answer2") }}</p>
          <!-- <p>1. Yangon – 100% orders are shipped with next day delivery. Orders placed after 3:00 pm on Friday and over the weekend are dispatched on Mondays.</p>
        <p>2.Other Cities in Myanmar – 100% orders are processed within 24 hours of payment provided the product ordered is in stock. Orders are not processed or shipped on Saturday, Sunday or major public holidays in Myanmar. Please consider shipping time as an estimate for intercity delivery due to COVID.</p>
        <p>3.Intercity Delivery – We deliver your orders to Yangon within the same day and 3-7 days delivery to townships in other cities across Myanmar. Our Care Companion will contact you directly provided your correct information if your township is in the area where we are unable to reach at the moment.</p> -->
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b
              >{{ $t("Customer.FAQ.Question3") }}</b
            >
          </p>
          <p>
            {{ $t("Customer.FAQ.Answer3") }}
          </p>
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b>{{ $t("Customer.FAQ.Question4") }}</b>
          </p>
          <p>
           {{ $t("Customer.FAQ.Answer4") }}
          </p>
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p><b>{{ $t("Customer.FAQ.Question5") }}</b></p>
          <p>
           {{ $t("Customer.FAQ.Answer5") }}
          </p>
          <!-- <p>Chat with us on My-Medicine Facebook Messenger</p>
        <p>Email us on care@mymedicine.com.mm</p> -->
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p><b>{{ $t("Customer.FAQ.Question6") }}</b></p>
          <p>
           {{ $t("Customer.FAQ.Answer6") }}
          </p>
          <!-- <p>
           Step 1. Click Scan and Pay in your KBZPay App<br>
           Step 2. Click Manual<br>
           Step 3. Select “Pay to merchant” option<br>
           Step 4. Enter code 70006301001 and click Pay<br>
           Step 5. Enter the total amount<br>
           Step 6. Enter the order confirmation number in the KBZPay “Add notes (optional)” field, and click Finish
        </p>
        <p>အဆင့် (၁) သင်၏ KBZPay application တွင် ‘Scan and Pay’ ကို နှိပ်ပါ။</p>
        <p>အဆင့် (၂) ‘Manual’ ကို နှိပ်ပါ။</p>
        <p>အဆင့် (၃) ‘Pay to merchant’ ကိုရွေးချယ်ပါ။</p>
        <p>အဆင့် (၄) ကုဒ်နံပါတ် 70006301001 ဟု ရိုက်ထည့်ပြီး ‘Pay’ ကို နှိပ်ပါ။</p>
        <p>အဆင့် (၅) သင်ပေးအပ်ရမည့် ငွေပမာဏကို ရိုက်ထည့်ပါ။</p>
        <p>အဆင့် (၆) KBZPay ၏ ‘Add notes (Optional)’ ကွက်လပ်တွင် သင်၏ My-Medicine မှ</p>
        <p>အော်ဒါနံပါတ်ကို ရိုက်ထည့်ပြီး ‘Finish’ ကိုနှိပ်ပါ။</p> -->
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b>
             {{ $t("Customer.FAQ.Question7") }}</b
            >
          </p>
          <p>
            {{ $t("Customer.FAQ.Answer7") }}
          </p>
        </div>

        <!-- <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b>
              {{ $t("Customer.FAQ.Question8") }}</b
            >
          </p>
          <p>
            {{ $t("Customer.FAQ.Answer8") }}
          </p>
        </div> -->

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b>
               {{ $t("Customer.FAQ.Question8") }}</b
            >
          </p>
          <p>
            {{ $t("Customer.FAQ.Answer8") }}
          </p>
          <p>{{ $t("Customer.FAQ.Answer8_Point1") }}</p>
          <p>{{ $t("Customer.FAQ.Answer8_Point2") }}</p>
          <p>
            {{ $t("Customer.FAQ.Answer8_Point3") }}
          </p>
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b>  {{ $t("Customer.FAQ.Question9") }}</b>
          </p>
          <p> {{ $t("Customer.FAQ.Answer9") }}</p>
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b> {{ $t("Customer.FAQ.Question10") }}</b>
          </p>
          <p>
             {{ $t("Customer.FAQ.Answer10") }}
          </p>
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b>  {{ $t("Customer.FAQ.Question11") }}</b>
          </p>
          <p>
            {{ $t("Customer.FAQ.Answer11") }}
          </p>
        </div>

        <div style="margin-left: 20px; margin-top: 20px" align="left">
          <p>
            <b>  {{ $t("Customer.FAQ.Question12") }}</b>
          </p>
          <p>
             {{ $t("Customer.FAQ.Answer12") }}
          </p>
        </div>

      </div>

      <!--OPD and Packages-->
      <div class="feature-tabs-segment" style="margin-top: 16px;">
        <div v-if="opdEnable" class="pt-4 pl-4 pb-4 opdBooking-tab feature-tab clickable-pointer" @click="redirectTo('opdBooking')" 
        :class="(opdEnable && packageEnable) ? 'width-50' : 'width-100'" >
          <div class="display-mobile">
            <div class="d-flex justify-center align-center">
              <img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/OPDiconHome.svg">
            </div>
          </div>
          
          <div class="display-web">
            <div class="d-flex justify-flex-start">
              <img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/OPDiconHome.svg">
            </div>
          </div>
          <p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="left" style="text-align: start; padding-right: 4px;">{{ $t("Customer.Home.OPD_Appointments") }}</p>
        </div>
        <div v-if="packageEnable" class="pt-4 pl-4 pb-4 packageBooking-tab feature-tab clickable-pointer" @click="redirectTo('packageBooking')"
        :class="(opdEnable && packageEnable) ? 'width-50' : 'width-100'" >
          <div class="display-mobile">
            <div class="d-flex justify-center align-center">
              <img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/packageIconHome.svg">
            </div>
          </div>
          
          <div class="display-web">
            <div class="d-flex justify-flex-start">
              <img class="icon-class" src="https://s3iconimages.mymedicine.com.mm/packageIconHome.svg">
            </div>
          </div>
          <p class="ma-0 pt-2 body-heading font-weight-semi-bold feature-text" align="left" style="text-align: start; padding-right: 4px;">{{ $t("Customer.Home.Lab_Packages") }}</p>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import NavBar from "../../../views/navbar.vue";
import axios from "axios";
import { axios_auth_instance_nba_admin } from '../../../utils/axios_utils';
import bottomnavbar from "./bottomnavbar.vue";
export default {
  name: "FAQ",
  data() {
    return {
      currentCustomer: "",
      isMobile: false,
      show_header: true,
      url: "",
      wherebyMeetLink : "",
      twilioMeetLink:"",
      twilioRoomName:'',
      opdEnable: true,
			packageEnable: true,
			HBAsettingResponse: null,
    };
  },
  methods: {
    redirectToHomePage() {
      this.$router.go(-1);
    },
    redirectTo(redirectionPage) {
			if(redirectionPage == 'opdBooking') {
				this.$router.push({
					name:'OPDHome'
				})
			} else if(redirectionPage == 'packageBooking') {
				this.$router.push({
					name:'OPDPackageHome'
				})
			}
		},
    // openDolbyPage(){
    //     this.$router.push({
    //     name: "DolbyDemoPage",
    //   });
    // },
    openWherebyPage(){
      this.$router.push({
        name : "WherebyTestPage",
        params : {meetingLink : this.wherebyMeetLink}
      });
    },
    openTwilioPage(){
      axios.post(process.env.VUE_APP_BACKEND_URL + "/twilio", {})
      .then((twilioMeetingLink) =>{
        console.log("twilioMeetingLink",twilioMeetingLink.data.room_name)
        this.twilioMeetLink =twilioMeetingLink.data.token
        this.twilioRoomName = twilioMeetingLink.data.room_name
        var twilioObj = {
          meetLink : this.twilioMeetLink,
          roomName : this.twilioRoomName
        }
        this.$router.push({
        name : "TwilioTestPage",
        params : {meetingLink : twilioObj}
      });
      })
      .catch((twilioError)=>{
        console.log("twilioError", twilioError)
      })

    }
  },
  async mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
       // document.title = "FAQ"
       if(this.$store.state.locale == "en")
      document.title = "FAQ"
    else
      document.title = "အမေးများသောမေးခွန်းများ"

    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");
    if (this.currentCustomer) {
      var verifyCustomerTokenData = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",
          verifyCustomerTokenData
        )
        .then((verifyCustomerTokenDataResponse) => {})
        .catch((verifyCustomerTokenDataError) => {
          if (verifyCustomerTokenDataError.response.status == 401) {
            this.$cookies.remove("customerToken");
            this.$router.push({
              name: "Login",
            });
          } else {
            this.$cookies.remove("customerToken");
            this.$router.push({
              name: "Login",
            });
          }
        });
    } else {
      this.$cookies.remove("customerToken");
      this.$router.push({
        name: "Login",
      });
    }
    try{ 
			this.HBAsettingResponse = await axios_auth_instance_nba_admin.get('/hba/pagewise/v1/HBAsettingStatus');
			console.log("hbasetting",this.HBAsettingResponse)
			this.opdEnable = this.HBAsettingResponse.data.data[0].availability_status === "AVAILABLE" ? true : false;
        	this.packageEnable = this.HBAsettingResponse.data.data[1].availability_status === "AVAILABLE" ? true : false;
		} catch{
			console.log('error while fetching data', this.HBAsettingResponse);
		}
  },
};
</script>

<style>
/* @media (min-width: 800px){
  .faqMainDiv{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }} */
</style>
